import { Col, Divider, List, Row } from "antd";
import React from "react";

type IProps = {
  title: string;
  data: any[];
};

const DescriptionList = (props: IProps) => {
  const { title, data } = props;

  return (
    <>
      <h2 className="gx-text-primary">{title}</h2>
      <Divider />
      <Row>
        <Col span={24}>
          <List
            grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
            size="small"
            dataSource={data}
            renderItem={(item: any) => (
              <List.Item
                className="gx-pb-2"
                style={{ borderBottom: "solid 1px #e8e8e8" }}
              >
                <List.Item.Meta
                  title={
                    <span className="gx-fs-sm gx-text-grey gx-font-weight-light">
                      {item.label}
                    </span>
                  }
                  description={
                    item.content ? (
                      item.content
                    ) : (
                      <span className="gx-text-danger">N/D</span>
                    )
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default DescriptionList;
