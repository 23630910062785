import { Layout } from "antd";
import React from "react";
import { useRouteMatch } from "react-router-dom";

import { Sidebar, Topbar } from "../../components";
import { App } from "../../routes";

const MainApp = () => {
  const { Content, Footer } = Layout;
  const match = useRouteMatch();

  return (
    <Layout className="gx-app-layout">
      <Sidebar />
      <Layout>
        <Topbar />
        <Content className="gx-layout-content">
          <App match={match} />
          <Footer>
            <div className="gx-layout-footer-content gx-font-weight-light gx-text-light">
              Todos los derechos reservados - TTU © 2021
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainApp;
