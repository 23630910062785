import React from "react";
import { Route, Switch } from "react-router-dom";

import AsyncComponent from "../util/AsyncComponent";

type IProps = { match: any };

const App = (props: IProps) => {
  const { match } = props;

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}dashboard`}
          component={AsyncComponent(() => import("./Dashboard"))}
        />
        <Route
          path={`${match.url}shipments`}
          component={AsyncComponent(() => import("./Shipments"))}
        />
        <Route
          path={`${match.url}users`}
          component={AsyncComponent(() => import("./Users"))}
        />
        <Route
          path={`${match.url}employees`}
          component={AsyncComponent(() => import("./Employees"))}
        />
        <Route
          path={`${match.url}oxxos`}
          component={AsyncComponent(() => import("./Oxxos"))}
        />
        <Route
          path={`${match.url}profile`}
          component={AsyncComponent(() => import("./Profile"))}
        />
        <Route
          path="*"
          component={AsyncComponent(
            () => import("../containers/Errors/Error404")
          )}
        />
      </Switch>
    </div>
  );
};

export default App;
