import { Form, FormInstance, Input } from "antd";
import React, { useEffect } from "react";

import { Moment } from "../../util";

type IProps = {
  form: FormInstance;
  name: string;
  fromDate?: string;
  fromTime?: string;
  label: string;
  required?: boolean;
  requiredMessage?: string;
  step?: number;
  min?: string;
  max?: string;
  bordered?: boolean;
};

const TimeSelect = (props: IProps) => {
  const {
    form,
    name,
    fromDate,
    fromTime,
    label,
    required = false,
    requiredMessage = "Fecha obligatoria",
    step = 60,
    min,
    max,
    bordered = true,
  } = props;

  useEffect(() => {
    const values = form.getFieldsValue();
    if (fromDate) {
      form.setFieldsValue({
        ...values,
        [name]: fromDate ? Moment(fromDate).format("HH:mm") : null,
      });
    } else if (fromTime) {
      form.setFieldsValue({
        ...values,
        [name]: fromTime.substr(0, 5),
      });
    }
  }, [form, name, fromDate, fromTime]);

  const onChange = (e: any) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, [name]: e.target.value });
    console.log("e.target.value", e.target.value);
  };

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: required,
          message: requiredMessage,
        },
      ]}
      hasFeedback
    >
      <Input
        type="time"
        min={min}
        max={max}
        step={step}
        pattern="[0-9]{2}:[0-9]{2}"
        bordered={bordered}
        className={bordered ? "gx-w-100" : "gx-input-underlined gx-w-100"}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default TimeSelect;
