import {
  DashboardOutlined,
  DropboxOutlined,
  BankOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { checkPermission } from "../../../auth";
import { CustomScrollbars } from "../../../util";
import SidebarLogo from "./SidebarLogo";

const SidebarContent = () => {
  const { pathname } = useSelector((state: any) => state.settings);
  const { authUser } = useSelector((state: any) => state.auth);
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme="dark"
            mode="inline"
          >
            <Menu.ItemGroup key="dashboard" className="gx-menu-group" title="">
              <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
                <Link to="/dashboard">
                  <span>Escritorio</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="shipments:all" icon={<DropboxOutlined />}>
                <Link to="/shipments/all">
                  <span>Envios</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="users:all" icon={<UserOutlined />}>
                <Link to="/users/all">
                  <span>Clientes</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="oxxos:oxxos" icon={<BankOutlined />}>
                <Link to="/oxxos/oxxos">
                  <span>Oxxos</span>
                </Link>
              </Menu.Item>
              {checkPermission(authUser?.employee_type_id, "employees:list") ? (
                <Menu.Item
                  key="employees:employees"
                  icon={<SolutionOutlined />}
                >
                  <Link to="/employees/employees">
                    <span>Empleados</span>
                  </Link>
                </Menu.Item>
              ) : null}
            </Menu.ItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default SidebarContent;
