import { CheckOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import React from "react";

type IProps = {
  title: string;
};

const FormSubmitButton = ({ title }: IProps) => (
  <Form.Item
    wrapperCol={{
      xs: {
        span: 24,
        push: 0,
      },
      sm: {
        span: 16,
        push: 8,
      },
    }}
  >
    <Button type="primary" htmlType="submit">
      <CheckOutlined /> <span>{title}</span>
    </Button>
  </Form.Item>
);

export default FormSubmitButton;
