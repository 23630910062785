import { SendOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";

import { userRecovery } from "../../redux/actions/Auth";
import { InfoView } from "../../components";

type IProps = {} & RouteComponentProps;

const Recovery = (props: IProps) => {
  const { history } = props;
  const { Title, Paragraph } = Typography;

  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);

  const onFinish = (values: any) => {
    dispatch(userRecovery(values));
  };

  useEffect(() => {
    if (token !== null) {
      history.push("/");
    }
  }, [token, history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={require("assets/images/login.png")} alt="TTU" />
            </div>
          </div>
          <div className="gx-app-login-content" style={{ minHeight: "250px" }}>
            <InfoView label="Procesando" errorTitle="Error al procesar">
              <Form
                initialValues={{}}
                name="recoveryForm"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                className="gx-login-form gx-form-row0"
              >
                <Title level={4}>Recupera tu contraseña</Title>
                <Paragraph className="gx-text-grey">
                  Introduce el correo electrónico de tu cuenta para recuperar tu
                  contraseña.
                </Paragraph>

                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "El email es obligatorio" },
                    { type: "email", message: "No es un email válido" },
                  ]}
                  hasFeedback
                  initialValue={null}
                >
                  <Input type="email" placeholder="Email de tu cuenta" />
                </Form.Item>

                <Form.Item>
                  <Row justify="space-around" align="middle" gutter={[0, 0]}>
                    <Col flex="auto">
                      <Link to="/login" className="gx-text-grey">
                        Iniciar sesión
                      </Link>
                    </Col>
                    <Col flex="220px">
                      <Button
                        type="primary"
                        className="gx-mb-0"
                        block
                        icon={<SendOutlined />}
                        htmlType="submit"
                      >
                        Recuperar contraseña
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>

              </Form>
            </InfoView>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recovery;
