import { Form } from "antd";
import React from "react";

type IProps = {
  title: string;
};

const FormSectionTitle = ({ title }: IProps) => (
  <Form.Item label={" "} colon={false}>
    <h3 className="gx-text-dark">{title}</h3>
  </Form.Item>
);

export default FormSectionTitle;
